<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-header">
        {{aLang.c_list}} ứng dụng
        <div class="card-header-actions"></div>
      </div>
      <div class="card-body">
        <div class="text-left">
          <b-collapse v-bind:visible="mData.tableRules.showFormSearch" id="myFormSearch">
            <b-card>
              <el-form
                ref="myForm"
                label-position="top"
                @submit.native.prevent
                :model="mData.tableRules.dataSearch.value"
                :rules="mData.tableRules.dataSearch.valid"
              >
                <b-row>
                  <b-col md="3">
                    <el-form-item label="Tên ứng dụng" prop>
                      <el-input v-model="mData.tableRules.dataSearch.value.name" clearable></el-input>
                    </el-form-item>
                  </b-col>
                  <b-col md="3">
                    <el-form-item label="Môi trường mặc định" prop>
                      <el-select v-model="mData.tableRules.dataSearch.value.defaultEnv" clearable placeholder="Chọn môi trường">
                        <el-option
                          :disabled="mData.viewMode == 'detail'"
                          v-for="item in mData.dataDefault.environments"
                          :key="item"
                          :label="item"
                          :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </b-col>
                </b-row>
                <el-form-item class="text-center mb-0">
                  <el-button
                    type="info"
                    native-type="submit"
                    plain
                    @click="fn_tableSearch()"
                  >{{aLang.c_search}}</el-button>
                </el-form-item>
              </el-form>
            </b-card>
          </b-collapse>
        </div>
        <b-row>
          <b-col md="8" class="p-0">
            <b-col md="4"></b-col>
          </b-col>
          <b-col md="4">
            <div class="text-right mb-2">
              <el-button
                @click="fn_tableShowFormSearch()"
                type="info"
                plain
              >{{aLang.c_showHideSearch}}</el-button>
              <el-button @click="fn_handle('create')" type="info" plain>{{aLang.c_create}}</el-button>
            </div>
          </b-col>
        </b-row>

        <el-table
          :data="mData.tableRules.data"
          border
          @selection-change="fn_tableSelectionChange"
          @sort-change="fn_tableSortChange"
          max-height="440"
          style="width: 100%"
          :default-sort="{prop: mData.tableRules.defaultSort[0], order: mData.tableRules.defaultSort[1]}"
        >
          <el-table-column
            type="index"
            :sortable="mData.tableRules.allowSorting"
            label="STT"
            min-width="200"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="code"
            :sortable="mData.tableRules.allowSorting"
            label="Mã ứng dụng"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="name"
            :sortable="mData.tableRules.allowSorting"
            label="Tên ứng dụng"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="defaultEnv"
            :sortable="mData.tableRules.allowSorting"
            label="Môi trường mặc định"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="lastModifiedTime"
            :sortable="mData.tableRules.allowSorting"
            label="Ngày cập nhật"
            min-width="200"
            align="center"
          ></el-table-column>
          <el-table-column :label="aLang.c_action" align="center" width="150px">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="fn_handle('detail',scope.$index, scope.row)"
                v-b-popover.hover.left="aLang.c_detail"
              >
                <i class="fa fa-eye"></i>
              </el-button>
              <el-button
                size="mini"
                @click="fn_handle('update',scope.$index, scope.row)"
                v-b-popover.hover.left="aLang.c_update"
              >
                <i class="icon-pencil icons"></i>
              </el-button>
               <el-button
                size="mini"
                @click="fn_handle('delete',scope.$index, scope.row)"
                v-b-popover.hover.left="aLang.c_delete"
              >
                <i class="icon-trash icons"></i>
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <b-row class="mt-2" v-if="mData.tableRules.allowPaging">
          <div class="col-md-4">
            <div
              v-if="mData.tableRules.allowSelect"
            >{{aLang.c_select}}: {{mData.tableRules.recordSelected.length}}</div>
          </div>
          <div class="col-md-8 text-right">
            <el-pagination
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
              :page-sizes="mData.tableRules.lengthMenu"
              :page-size="mData.tableRules.limit"
              :current-page="mData.tableRules.page"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="mData.tableRules.total"
              :pager-count="5"
            ></el-pagination>
          </div>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageService from "../../../service/LanguageService";
import MethodService from "../../../service/MethodService";
import AccountApi from "../../../moduleApi/AccountApi";
import ApplicationApi from "../../../moduleApi/ApplicationApi";
import ApplicationModel from "./ApplicationModel";

export default {
  data() {
    return {
      aRole: MethodService.checkRole,
      aLang: LanguageService.lang,
      aElement: MethodService.element,
      mData: {
        moduleName: "Application", //requied
        tableRules: MethodService.copyObject(ApplicationModel.tableRules),
        dataDefault: {
          listFindNongTrai: [],
          environments: ['Production', 'Staging', 'Development']
        }
      }
    };
  },

  methods: {
    //get list table
    async fn_initTable() {
      let dataFilter = {
            filters: this.mData.tableRules.filters
        };
      if (this.mData.tableRules.allowPaging) {
          dataFilter.limit = this.mData.tableRules.limit;
          dataFilter.offset = this.mData.tableRules.offset;
      }
      if (this.mData.tableRules.allowSorting) {
          dataFilter.sort = this.mData.tableRules.sort;
      }

      if (this.mData.tableRules.showUrl) {
          this.$router.replace({
            name: "Admin-" + this.mData.moduleName + "-list",
            query: {
              limit: this.mData.tableRules.limit,
              page: this.mData.tableRules.page,
              sort: this.mData.tableRules.sort,
              filters: this.mData.tableRules.filters,
              showFormSearch: this.mData.tableRules.showFormSearch
            }
          }).catch(err => {});
      }
      let res = await ApplicationApi.list(dataFilter);
      res.result.forEach(item => {
          item.lastModifiedTime = MethodService.formatDate(item.lastModifiedTime, 'date')
      });
      this.mData.tableRules.data = res.result;
      //end get name
      this.mData.tableRules.total = res.meta.total;
      this.mData.tableRules.page = res.meta.offset / res.meta.limit + 1;
      if (
        this.mData.tableRules.offset == res.meta.total &&
        this.mData.tableRules.offset != 0
      ) {
        this.mData.tableRules.offset = 0;
        this.mData.tableRules.page = 1;
        this.fn_initTable();
      }
    },

    //xử lý dữ liệu đầu ra
    async fn_changeData(resData) {
      let nong_trai_id = [];
      let accounts = [];

      nong_trai_id = MethodService.findListItemDifferent(
        resData,
        "nong_trai_id"
      );

      accounts = MethodService.findListItemDifferent(resData, "id");

      let reqNongTraiApi = NongTraiApi.list({
        limit: nong_trai_id.length,
        filters: "id:in:" + nong_trai_id.join(",")
      });

      let reqAccountApi = AccountApi.list({
        limit: accounts.length,
        filters: "id:in:" + accounts.join(",")
      });

      let resNongTraiApi = await reqNongTraiApi;
      let resAccountApi = await reqAccountApi;

      $.each(resData, (index, item) => {
        let resultNongTrai = resNongTraiApi.result.find(element => {
          return element.id == item.nong_trai_id;
        });
        if (resultNongTrai) {
          item.nong_trai_name = resultNongTrai.ten;
        } else {
          item.nong_trai_name = "";
        }

        let resultAccount = resAccountApi.result.find(element => {
          return element.id == item.id;
        });
        if (resultAccount) {
          item.disabled = resultAccount.disabled;
        } else {
          item.disabled = false;
        }
      });

      return resData;
    },

    //click button
    fn_handle(type, scope, row) {
      if (type == "create") {
        this.$router.push({
          name: "Admin-" + this.mData.moduleName + "-create"
        });
      }
      if (type == "update") {
        this.$router.push({
          name: "Admin-" + this.mData.moduleName + "-update",
          params: { id: row.id }
        });
      }
      if (type == "detail") {
        this.$router.push({
          name: "Admin-" + this.mData.moduleName + "-detail",
          params: { id: row.id }
        });
      }
      if (type == "delete") {
        this.$confirm(LanguageService.lang.c_deleteConfirm, {
          confirmButtonText: LanguageService.lang.c_agree,
          cancelButtonText: LanguageService.lang.c_cancel,
          type: "warning"
        })
          .then(() => {
            this.fn_delete(row.id);
          })
          .catch(() => {});
      }
      if (type == "changePass") {
        this.$router.push({
          name: "Admin-" + this.mData.moduleName + "-update",
          params: { id: row.id },
          query: {
            viewMode: "changePass"
          }
        });
      }
    },

    //delete
    async fn_delete(id) {
      await ApplicationApi.delete(id);
      this.fn_initTable();
    },

    //event table
    fn_tableSelectionChange(val) {
      var _this = this;
      this.mData.tableRules.recordSelected = [];
      val.forEach(function(element) {
        _this.mData.tableRules.recordSelected.push(element.id);
      });
    },

    fn_tableSortChange(column) {
      if (column.order == "ascending") {
        this.mData.tableRules.sort = column.prop;
      } else if (column.order == "descending") {
        this.mData.tableRules.sort = "-" + column.prop;
      } else {
        this.mData.tableRules.sort = "";
      }
      this.fn_initTable();
    },
    // search
    fn_tableSearch() {
      this.$refs["myForm"].validate(async valid => {
        if (valid && !this.mData.dataDefault.validErrorTime) {
          this.mData.tableRules.filters = MethodService.filterTable(
            JSON.stringify(this.mData.tableRules.dataSearch)
          );
          this.mData.tableRules.offset = 0;
          this.mData.tableRules.sort = "";
          this.fn_initTable();
        } else {
          MethodService.stopLadda();
          return false;
        }
      });
    },

    //event table
    fn_tableSizeChange(limit) {
      this.mData.tableRules.limit = limit;
      this.fn_tableChangeOffset(this.mData.tableRules.page);
    },
    fn_tableCurentChange(page) {
      this.fn_tableChangeOffset(page);
    },
    fn_tablePrevClick(page) {
      this.fn_tableChangeOffset(page);
    },
    fn_tableNextClick(page) {
      this.fn_tableChangeOffset(page);
    },

    fn_tableChangeOffset(page) {
      this.mData.tableRules.page = page;
      this.mData.tableRules.offset =
        (this.mData.tableRules.page - 1) * this.mData.tableRules.limit;
      this.fn_initTable();
    },

    fn_tableShowFormSearch() {
      this.mData.tableRules.showFormSearch = !this.mData.tableRules
        .showFormSearch;
      let query = Object.assign({}, this.$route.query);
      query.showFormSearch = this.mData.tableRules.showFormSearch;
      this.$router.push({
        name: "Admin-" + this.mData.moduleName + "-list",
        query: query
      });
    },
    fn_formatterDate(row, column, cellValue, index) {
      return MethodService.formatDate(cellValue, "date");
    },

    fn_formatterDatetime(row, column, cellValue, index) {
      return MethodService.formatDate(cellValue, "datetime");
    },

    fn_formatterRoles(row, column, cellValue, index) {
      var roles = "";
      cellValue.forEach(item => {
        for (let i = 0; i < this.mData.dataDefault.roles.length; i++) {
          if (item == this.mData.dataDefault.roles[i].value) {
            item = this.mData.dataDefault.roles[i].label;
            roles += item + " ,";
          }
        }
      });
      roles = roles.slice(0, -1);
      return roles;
    }
  },

  //run after render view
  created() {
      this.fn_initTable();
  },
  mounted() {
    // this.fn_initTable();
  }
};
</script>
